<template lang="pug">
div.boxSectionHeaderAndBody
  div#navtop(:class="{'anchoEscritorioSubnavCerrado': subNavClosed}")
    div.sectionmenu
      div.firstlevel
        router-link(:to="{name: 'HealthyPokeSatisfactionHome', params: {paramCOD: this.paramCOD}, query: queryParams}" style="border-bottom: 2px solid #F41938; font-weight: 600;") #[translate Datos QR]
        select-country-and-logout
  router-view

</template>

<script>
import * as types from '@/store/mutation-types'
import commonMixins from "@/mixins/common.js"
import SelectCountryAndLogout from "@/components/common/SelectCountryAndLogout.vue"
export default {
  props: ['paramCOD', 'type', 'site', 'typeRestaurant', 'after', 'before'],
  mixins: [commonMixins],
  components: { SelectCountryAndLogout },
  created() {
    this.$store.commit(types.UPDATE_SECTION, 3)
    this.loadUrlParams()
  },
}
</script>